.flex {
  display: flex;
  .flexed {
    flex: 1 1 auto; // IE11 requires all three values
  }
  .p25 {
    max-width: 25%;
    width: 25%;
  }
}

.flex.columns {
  .flexed:not(:first-child) {
    padding-left: 20px
  }
}
