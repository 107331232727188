@charset "UTF-8";

@font-face {
  font-family: "ie3";
  src: url("/fonts/ie3.woff?569a4d51dd7e67c62b74fabb9ac360c1") format("woff");
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: ie3 !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-status-unknown:before,
.icon-status-alert:before {
  color: $statusAlertColor;
  content: "\f116";
}

.icon-status-error:before {
  content: "\f104";
  color: $statusErrorColor;
}

.icon-clear:before {
  content: "\f101";
}

.icon-status-ok:before {
  color: $statusOkColor;
  content: "\f10d";
}

.icon-trash:before {
  content: "\f115";
}

.icon-upload:before {
  content: "\f106";
  display: inline-block;
  font-size: 18px;
  transform: translate(2px, 5px) rotate(270deg);
  height: 1rem;
}