@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400%3b0,600%3b0,700%3b1,400%3b1,600%3b1,700&display=swap);
.dropZone {
  position: relative;
  width: 100%;
  padding: 10px 0 20px; }
  .dropZone .fileUpload {
    position: relative;
    display: inline-block; }
    .dropZone .fileUpload .modern {
      display: none; }
    .dropZone .fileUpload .legacy {
      position: absolute;
      z-index: 100;
      top: 0px;
      left: 0px; }
    .dropZone .fileUpload .pct {
      display: inline-block; }
    .dropZone .fileUpload button.icon.canceller {
      display: inline-block;
      font-size: 16px;
      transform: translate(2px, 2px);
      padding: 2px 0 0; }
    .dropZone .fileUpload button.icon.canceller:focus {
      box-shadow: none; }
    .dropZone .fileUpload.isIE .fileHandler {
      cursor: default; }
  .dropZone .fileHandler {
    padding: 0 3px;
    cursor: pointer;
    position: relative;
    display: inline-block; }
  .dropZone .checked .fileHandler.hasFile {
    font-weight: bold; }

.dropZone.drag {
  box-shadow: 0 0 0 0.2rem rgba(235, 200, 0, 0.5); }

.dropZone.dropped .fileHandler {
  box-shadow: 0 0 0 0.2rem rgba(235, 200, 0, 0.5); }

.fileHandler.error {
  color: #cc0000; }

.flex {
  display: flex; }
  .flex .flexed {
    flex: 1 1 auto; }
  .flex .p25 {
    max-width: 25%;
    width: 25%; }

.flex.columns .flexed:not(:first-child) {
  padding-left: 20px; }

label {
  padding: 0 3px;
  display: inline-block; }

.stacked label {
  padding: 0 0 5px 0;
  display: block; }

.i400 {
  width: 400px;
  max-width: 100%; }

.t640,
.i640 {
  width: 640px;
  max-width: 100%; }

.i200 {
  width: 200px; }

.im120 {
  min-width: 120px; }

.t80 {
  height: 80px; }

.t120 {
  height: 120px; }

.section p.radio,
p.radio {
  line-height: 20px; }

.radioText {
  display: inline-block;
  padding: 0 3px; }

.checked {
  font-weight: 700; }

.recaptcha {
  margin: 6px 0;
  min-height: 78px; }

.error input[type="text"],
.error textarea,
.error input[type="password"] {
  border-color: #cc0000;
  border-width: 2px;
  margin: -1px; }

.errormsg {
  display: inline-block;
  font-weight: bold;
  color: #cc0000;
  padding-left: 1rem; }

* {
  box-sizing: border-box; }

body {
  font-family: "Open Sans", verdana, sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 16px;
  background-color: white;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  min-height: 100vh; }

:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  transition: box-shadow 200ms; }

a,
span.link {
  text-decoration: none;
  color: #0066a1; }

a:hover,
span.link:hover {
  color: #000;
  text-decoration: underline;
  transition: color 200ms; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", verdana, sans-serif;
  font-weight: 700;
  padding: 0;
  margin: 0;
  line-height: 1.2em;
  color: #3c3a3d; }

h2 {
  font-size: 21px; }

h3 {
  font-size: 18px; }

h4 {
  font-size: 18px; }

p {
  margin: 0;
  padding: 0;
  font-size: 16px; }

ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1.5em;
  padding: 0;
  font-size: 16px; }

ol li {
  padding: 0 0 10px 0; }

ol ul li {
  padding: 0; }

li.noble {
  list-style: none; }

select {
  font-family: "Open Sans", verdana, sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 6px;
  line-height: 1.33;
  border: 1px solid #ccc;
  border-radius: 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 100 80' width='100' height='80'%3E%3Cdefs%3E%3Cpath d='M 0 0 L 50 80 L 100 0 Z' id='a7ecCj0rP'%3E%3C/path%3E%3C/defs%3E%3Cg%3E%3Cuse xlink:href='%23a7ecCj0rP' opacity='1' fill='%23000000' fill-opacity='1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .5em auto, 100%; }

select::-ms-expand {
  display: none; }

input[type="text"],
textarea {
  border: 1px solid #ccc;
  border-radius: 2px;
  font-family: "Open Sans", verdana, sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 6px;
  line-height: 1.33; }

input[type="text"].readonly,
textarea.readonly {
  border-color: #ededed;
  background-color: #ededed; }

input[type="text"].readonly:focus,
textarea.readonly:focus {
  box-shadow: none; }

.skipToMain:active,
.skipToMain:focus {
  position: static;
  clip: auto;
  width: auto;
  height: auto;
  overflow: visible;
  text-decoration: underline; }

.nav {
  background-color: #333;
  list-style-type: none;
  font-size: 12px;
  color: #ccc; }
  .nav ul {
    padding: 0.5em 0 0.5em 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    margin: 0; }
  .nav li {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-left: 1px solid #555; }
    .nav li a,
    .nav li a:active,
    .nav li a:visited {
      display: inline-block;
      padding: .2rem 1rem;
      color: #ccc; }
  .nav li.join {
    flex: 1 1;
    text-align: right;
    border-left: none; }
  .nav li:first-child {
    border-left: none; }
  .nav .logo {
    width: 103px;
    height: 30px;
    max-width: 103px; }
    .nav .logo a {
      padding: 7px 20px 0 0;
      display: block;
      float: right; }
    .nav .logo img {
      width: 103px;
      height: 30px; }

.banner {
  position: relative;
  padding-bottom: 1rem;
  background-color: white;
  height: 90px; }
  .banner h1 {
    line-height: 72px;
    font-size: 36px;
    font-weight: 600;
    height: 72px; }
    .banner h1 .title {
      white-space: nowrap;
      position: relative;
      display: block; }
    .banner h1 .productLogo {
      display: block;
      position: absolute;
      width: 36px;
      height: 36px;
      background-image: url(/images/refprep.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 26px 26px;
      top: 5px;
      left: 0; }
    .banner h1 a {
      color: #000; }
    .banner h1 a:hover {
      color: #0066a1;
      text-decoration: none; }
  .banner .edifix {
    flex: 1 1 100%;
    padding-left: 20px;
    transform: translate(0, 2px); }
    .banner .edifix a {
      display: block;
      float: right; }
    .banner .edifix img {
      width: 125px; }
  .banner .mw240 {
    width: 260px;
    height: 70px;
    max-width: 260px;
    padding-left: 20px; }
    .banner .mw240 a {
      display: block;
      float: right; }
    .banner .mw240 img {
      width: 240px;
      height: 70px; }
  .banner .mw120 {
    width: 150px;
    display: block; }
    .banner .mw120 img {
      width: 120px; }

.top-nav:after {
  position: relative;
  display: block;
  background-color: white;
  content: "";
  width: 100%;
  height: 12px;
  font-size: 0;
  line-height: 0;
  box-sizing: border-box;
  border: solid;
  border-width: 0 12px 12px 0;
  border-color: #000 #333 transparent #000; }

.subNavBar {
  background-color: #dedede;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: right;
  font-size: 11px; }
  .subNavBar li {
    display: inline-block;
    list-style-type: none; }
    .subNavBar li a {
      display: inline-block;
      padding: 11px;
      color: #777; }
    .subNavBar li a:hover,
    .subNavBar li a:active {
      color: #333; }
  .subNavBar li:last-child {
    background-color: #717171; }
    .subNavBar li:last-child a {
      color: white;
      padding-right: 22px; }

.subNavBar:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 7.5px solid #fff;
  border-bottom: 7.5px solid #fff;
  border-color: #fff #fff transparent transparent;
  border-style: solid;
  border-width: 7.5px; }

#main {
  position: relative;
  display: block;
  padding-bottom: 1.5rem;
  background-color: white; }

a.icon,
button.icon,
button.icon:hover {
  padding: 0;
  color: #0066a1;
  background-color: transparent;
  transition: color 200ms;
  margin: 0;
  border: none;
  vertical-align: inherit; }

a.icon.disabled,
a.icon.disabled:hover,
button.icon.disabled,
button.icon.disabled:hover {
  color: #bebebe; }

.error button.icon:not(.disabled) {
  color: #cc0000; }

a.icon:hover,
button.icon:hover {
  color: #000; }

button.link {
  background-color: transparent;
  display: inline;
  padding: 0;
  margin: 0;
  border: none;
  color: #0066a1; }

button.link:hover,
button.link:active {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: #000; }

.btn,
button {
  display: inline-block;
  padding: 6px 12px;
  border: 1px solid #e37222;
  border-radius: 2px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  color: white;
  background-color: #e37222;
  vertical-align: middle;
  transition: background-color 200ms, color 200ms;
  line-height: 1.25; }

.btn.lg,
button.lg {
  font-size: 22px;
  padding: 6px 36px;
  text-transform: uppercase; }

.btn.secondary,
button.secondary {
  color: #e37222;
  background-color: white; }

.btn.secondary:hover,
button.secondary:hover,
.btn.secondary:active,
button.secondary:active {
  color: #b55b1b;
  background-color: white;
  border-color: #b55b1b; }

.btn.loading,
button.loading,
.btn.loading:hover,
button.loading:hover,
.btn.loading:active,
button.loading:active {
  background-color: #cccccc;
  border-color: #cccccc;
  color: #888888; }

.btn.disabled,
button.disabled,
.btn.disabled:hover,
button.disabled:hover,
.btn.disabled:focus,
button.disabled:focus,
.btn.disabled:active,
button.disabled:active {
  background-color: #ededed;
  color: #bebebe;
  border-color: #ededed; }

.btn.error,
button.error,
.btn.error:hover,
button.error:hover,
.btn.error:focus,
button.error:focus,
.btn.error:active,
button.error:active {
  background-color: #ededed;
  border-color: #ededed;
  color: #bebebe; }

.btn:hover,
button:hover,
.btn:active,
button:active {
  background-color: #b55b1b;
  border-color: #b55b1b;
  transition: background-color 200ms;
  color: white;
  text-decoration: none; }

.btn:focus,
button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(235, 200, 0, 0.25); }

.btn + button,
button + .btn,
.btn + .btn,
button + button {
  margin-left: 1em; }

.flexColumns {
  display: flex; }
  .flexColumns .flexColumn50 {
    width: 50%; }

.fright,
button.fright.link,
button.fright.link:hover,
button.fright.link:active {
  float: right;
  display: block; }

h2 button.fright.link,
h2 button.fright.link:hover,
h2 button.fright.link:active {
  margin-top: 10px; }

.center {
  text-align: center; }

@media (max-width: 799px) {
  .flexColumns {
    display: block; }
    .flexColumns .flexColumn50 {
      width: auto; } }

/* 
 * For example
 * 600px wide screen = 
 * 0.05 * (.75 * 600px - 30px) + 15px
 * 0.05 * (370px) + 15px
 * 18.5px + 15px = 33.5px
 * 1388px = 65.55 
 * 1200px = 58.5px
 * 300px = 24.75px
 */
/* 3.75vw + 13.5 */
@media screen and (max-width: 1388px) {
  .banner,
  #main {
    padding-right: calc( ( 0.05 * ( 75vw - 30px ) ) + 15px);
    padding-left: calc( ( 0.05 * ( 75vw - 30px ) ) + 15px); } }

/*
 * (1389 * .75 - 900) / 2 = 70.875
 * 37.5vw - 450px where 37.5vw is minimum of 520.875
 */
@media screen and (min-width: 1389px) {
  .banner,
  #main {
    padding-left: calc( ( 75vw - 900px ) / 2);
    padding-right: calc( ( 75vw - 900px ) / 2); } }

.section .bounds {
  padding: 20px 20px 10px 20px; }

.section h2 {
  margin-bottom: 21px; }

.section h3 {
  margin-top: 18px;
  margin-bottom: 15px; }

.section h4 {
  margin-top: 18px;
  margin-bottom: 15px; }

.section p,
.section ul {
  margin-bottom: 16px; }

.section ol ul,
.section ul ul {
  margin-bottom: 0; }

.section h2.push, .section h3.push, .section h4.push, .section p.push, .section .lip.push {
  padding-top: 8px; }

.section .lip.half {
  padding-top: 8px; }

.section .lip {
  display: block;
  padding-bottom: 16px; }

.section.bordered {
  border: 1px solid #dedede; }

.disabled h3,
.disabled p {
  color: #c0c0c0; }

#footer {
  background-color: #333;
  padding: 0 30px 1rem; }
  #footer .nav {
    color: white; }
    #footer .nav ul {
      align-items: center;
      justify-content: center; }
      #footer .nav ul li {
        border-width: 0; }
        #footer .nav ul li a,
        #footer .nav ul li a:active,
        #footer .nav ul li a:visited {
          color: white; }
  #footer p {
    font-size: 13px;
    text-align: center;
    color: white; }
    #footer p a,
    #footer p a:visited {
      color: #f5ce7a;
      text-decoration: underline; }
    #footer p a:active {
      color: white; }

@media (max-width: 599px) {
  .ieee-meta {
    display: none; } }

.j {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; }

@font-face {
  font-family: "ie3";
  src: url("/fonts/ie3.woff?569a4d51dd7e67c62b74fabb9ac360c1") format("woff"); }

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: ie3 !important;
  font-style: normal;
  font-weight: normal !important;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-status-unknown:before,
.icon-status-alert:before {
  color: orange;
  content: "\f116"; }

.icon-status-error:before {
  content: "\f104";
  color: #cc0000; }

.icon-clear:before {
  content: "\f101"; }

.icon-status-ok:before {
  color: #008542;
  content: "\f10d"; }

.icon-trash:before {
  content: "\f115"; }

.icon-upload:before {
  content: "\f106";
  display: inline-block;
  font-size: 18px;
  transform: translate(2px, 5px) rotate(270deg);
  height: 1rem; }

/* react-modal custom styles */
/*
@media screen and (max-width: 1388px) {
  .modal {
    top: 45px;
	  left: calc( 3.75vw + 5px );
    right: calc( 3.75vw + 5px );
	  bottom: 45px;
  }
}

@media screen and (min-width: 1389px) {
  .modal { 
    top: 45px;
	  left: calc( 37.5vw - 440px );
    right: calc( 37.5vw - 440px );
	  bottom: 45px;
  }
}
*/
.confirm.modal {
  top: calc(50vh - 100px);
  left: calc(50vw - 200px);
  width: 400px;
  min-height: 100px;
  background-color: white; }

.loader.modal {
  outline: none;
  box-shadow: none; }
  .loader.modal .modalCloser {
    display: none; }

.modalCloser {
  font-size: 36px;
  display: block;
  position: absolute;
  right: 10px;
  top: 9px;
  line-height: 24px; }

.modal {
  position: absolute;
  box-sizing: border-box; }
  .modal .bounds {
    padding: 20px; }
  .modal .uploadError {
    display: flex;
    flex-wrap: nowrap; }
  .modal .statusIcon {
    flex-shrink: 0;
    width: 50px; }
    .modal .statusIcon .icon-status-alert {
      display: inline-block;
      font-size: 36px; }
  .modal .statusMessage {
    flex-grow: 1;
    padding-right: 50px; }
  .modal button.lg {
    font-size: 16px;
    min-width: 100px; }
  .modal .errorReset {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; }
  .modal .modalActions {
    padding-top: 10px;
    text-align: center; }

.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.333); }

.icon-status-alert:before,
.icon-status-error,
.icon-status-ok:before {
  background-color: white; }

.processingPanel {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1rem;
  position: relative;
  overflow: auto; }
  .processingPanel ul.processingStatus {
    padding: 0.5rem 24px;
    list-style: none;
    margin: 0;
    font-size: 16px;
    font-weight: 300; }
    .processingPanel ul.processingStatus li {
      position: relative;
      font-size: 16px; }
      .processingPanel ul.processingStatus li .spinnerSpot {
        background-color: white;
        position: absolute;
        left: -22px;
        top: 0;
        z-index: 1; }
      .processingPanel ul.processingStatus li .icon-small.icon-status-ok {
        position: absolute;
        font-size: 16px;
        color: #888888;
        top: 2px;
        left: -22px; }
      .processingPanel ul.processingStatus li .icon-small.icon-status-ok:before {
        color: #888888; }
      .processingPanel ul.processingStatus li.status-error, .processingPanel ul.processingStatus li.status-alert, .processingPanel ul.processingStatus li.status-unknown, .processingPanel ul.processingStatus li.status-complete {
        position: relative;
        font-weight: bold; }
        .processingPanel ul.processingStatus li.status-error .icon-status-alert,
        .processingPanel ul.processingStatus li.status-error .icon-status-error,
        .processingPanel ul.processingStatus li.status-error .icon-status-unknown,
        .processingPanel ul.processingStatus li.status-error .icon-status-ok, .processingPanel ul.processingStatus li.status-alert .icon-status-alert,
        .processingPanel ul.processingStatus li.status-alert .icon-status-error,
        .processingPanel ul.processingStatus li.status-alert .icon-status-unknown,
        .processingPanel ul.processingStatus li.status-alert .icon-status-ok, .processingPanel ul.processingStatus li.status-unknown .icon-status-alert,
        .processingPanel ul.processingStatus li.status-unknown .icon-status-error,
        .processingPanel ul.processingStatus li.status-unknown .icon-status-unknown,
        .processingPanel ul.processingStatus li.status-unknown .icon-status-ok, .processingPanel ul.processingStatus li.status-complete .icon-status-alert,
        .processingPanel ul.processingStatus li.status-complete .icon-status-error,
        .processingPanel ul.processingStatus li.status-complete .icon-status-unknown,
        .processingPanel ul.processingStatus li.status-complete .icon-status-ok {
          position: absolute;
          left: -24px;
          top: 0;
          font-size: 20px;
          z-index: 1; }

@keyframes flow {
  0% {
    background-position: 100%, 0; }
  80% {
    background-position: 0%, 0; } }

.spinnerPage {
  display: block;
  position: absolute;
  top: calc(50vh - 50px);
  left: calc(50vw - 50px);
  width: 100px;
  height: 100px;
  animation: spin 1.5s infinite linear;
  z-index: 200; }

/* h/t https://tobiasahlin.com/spinkit/ */
.spinnerSpot {
  display: inline-block; }
  .spinnerSpot .spinnerRect {
    margin: 0 1px;
    display: inline-block;
    width: 3px;
    height: 12px;
    background-color: #888888;
    animation: sk-bouncedelay 1.2s infinite ease-in-out; }
  .spinnerSpot .spinnerRect1 {
    animation-delay: -0.32s; }
  .spinnerSpot .spinnerRect2 {
    animation-delay: -0.16s; }
  .spinnerSpot .spinnerRect3 {
    animation-delay: 0; }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scaleY(0);
    transform-origin: bottom; }
  40% {
    transform: scaleY(1);
    transform-origin: bottom; } }

.skeletonRow {
  background-color: #f3f3f3;
  height: 1.5rem;
  width: 70%;
  margin: 1rem 0; }

.skeletonPulse {
  background: linear-gradient(-90deg, #e7e7e7 0%, #f3f3f3 50%, #e7e7e7 100%);
  background-size: 400% 400%;
  animation: pulse 2s ease-in-out infinite;
  transition: width 2s; }

@keyframes pulse {
  0% {
    background-position: 0% 0%; }
  100% {
    background-position: -135% 0%; } }

.btn.loading .spinner,
button.loading .spinner {
  background-color: #888888;
  background: linear-gradient(to top right, #888888 35%, rgba(136, 136, 136, 0) 70%); }

.btn.loading .spinner:before,
button.loading .spinner:before {
  background-color: #cccccc; }

.btn.loading .spinner:after,
button.loading .spinner:after {
  background-color: #cccccc; }

/* h/t https://projects.lukehaas.me/css-loaders/ */
.spinner {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  animation: spin 1.5s infinite linear;
  border-radius: 50%;
  background-color: white;
  background: linear-gradient(to right, white 10%, rgba(255, 255, 255, 0) 42%);
  vertical-align: -2px; }

.spinner:before {
  display: block;
  width: 50%;
  height: 50%;
  background-color: white;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: ''; }

.spinner:after {
  display: block;
  background-color: black;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.taglist {
  list-style-type: none;
  margin: 0; }
  .taglist .tag {
    display: inline-block;
    border-color: rgba(11, 81, 114, 0.4);
    padding: 3px 10px;
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
    background-color: rgba(209, 230, 239, 0.5); }
  .taglist .tag + .tag {
    margin-left: 6px; }
  .taglist .begone {
    margin: 1px 0 0 9px;
    font-size: 13px; }

.textEntry {
  position: relative;
  height: 50vh;
  min-height: 40px; }
  .textEntry textarea {
    width: 100%;
    height: 100%;
    border-color: #e0c377; }
  .textEntry.over textarea,
  .textEntry.entry textarea,
  .textEntry textarea:focus,
  .textEntry textarea:hover {
    box-shadow: 0 0 0 0.2rem rgba(235, 200, 0, 0.25); }

.dropZone.drag .textEntry.over textarea,
.dropZone.drag textarea:hover {
  box-shadow: none; }

.dropZone.text .textEntry {
  box-shadow: 0 0 0 0.2rem rgba(235, 200, 0, 0.5); }

.dropZone.text textarea {
  box-shadow: 0 0 0 0.2rem rgba(235, 200, 0, 0.25); }

