.taglist {
  list-style-type: none;
  margin: 0;
  .tag {
    display: inline-block;
    border-color: $tagBorderColor;
    padding: 3px 10px;
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
    background-color: $tagBgColor;
  }
  .tag + .tag {
    margin-left: 6px;
  }
  .begone {
    margin: 1px 0 0 9px;
    font-size: 13px;
  }
}
