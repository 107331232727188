/* react-modal custom styles */

/*
@media screen and (max-width: 1388px) {
  .modal {
    top: 45px;
	  left: calc( 3.75vw + 5px );
    right: calc( 3.75vw + 5px );
	  bottom: 45px;
  }
}

@media screen and (min-width: 1389px) {
  .modal { 
    top: 45px;
	  left: calc( 37.5vw - 440px );
    right: calc( 37.5vw - 440px );
	  bottom: 45px;
  }
}
*/

.confirm.modal {
  top: calc(50vh - 100px);
  left: calc(50vw - 200px);
  width: 400px;
  min-height: 100px;
  background-color: $modalBgColor;
}

.loader.modal {
  outline: none;
  box-shadow: none;
  .modalCloser {
    display: none;
  }
}

.modalCloser {
  font-size: 36px;
  display: block;
  position: absolute;
  right: 10px;
  top: 9px;
  line-height: 24px;
}

.modal {
  position: absolute;
  box-sizing: border-box;
  .bounds {
    padding: 20px;
  }
  .uploadError {
    display: flex;
    flex-wrap: nowrap;  
  }
  .statusIcon {
    .icon-status-alert {
      display: inline-block;
      font-size: 36px;
    }
    flex-shrink: 0;
    width: 50px;
  }
  .statusMessage {
    flex-grow: 1;
    padding-right: 50px;
  }
  button.lg {
    font-size: 16px;
    min-width: 100px;
  }
  .errorReset {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .modalActions {
    padding-top: 10px;
    text-align: center;
  }
}

.overlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $overlayBgColor;
}