// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400%3b0,600%3b0,700%3b1,400%3b1,600%3b1,700&display=swap');

// $defaultFont: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
// $headingFont: formata, Trebuchet MS, sans-serif;
$defaultFont: 'Open Sans', verdana, sans-serif;
$headingFont: 'Open Sans', verdana, sans-serif;
$defaultFontSize: 16px;

// Colors
$bgBody: white;
$bgDefault: white;
$bgNavBar: #333;
$bgFooter: #333;

$formBorderColor: #ccc;

$fontColor: #000;
$headingColor: #3c3a3d;

$footerNavColor: white;
$footerFontColor: white;
$footerLinkColor: #f5ce7a;

$navColor: #ccc;
$navPipeColor: #555;

$linkColor: #0066a1;
$linkColorHover: #000;

$statusAlertColor: orange;
$statusOkColor: #008542;

$buttonColor: white;
$buttonBgColor: #e37222;
$buttonBgHoverColor: #b55b1b;
$buttonBgDisabledColor: #ededed;
$buttonDisabledColor: #bebebe;
$buttonSpinnerColor: #888888;
$buttonBgSpinnerColor: #cccccc;

$buttonIconColor: $linkColor;
$buttonIconColorHover: $linkColorHover;

$subNavBgColor: #dedede;
$borderColorLight: #dedede;
$subNavLink: #777;
$subNavHoverLink: #333;
$subNavLastLinkBgColor: #717171;
$subNavLastLinkColor: white;

$dropZoneBorderColor: #f5ce7a;
$dropZoneBgColor: #fcefd3;
$fileSelectBgColor: #fff7e9;
$fileSelectBorderColor: #e0c377;
$fileSelectHoverBorderColor: #6e613f;

$modalBgColor: white;
$overlayBgColor: rgba(0,0,0,0.333);

$textDisabledColor: #c0c0c0;

$progressStepColor: rgb(11, 81, 114);
$progressFgColor: rgba(11, 81, 114, 0.4);
$progressBgColor: rgb(209, 230, 239);

$tagBorderColor: rgba(11, 81, 114, 0.4);
$tagBgColor: rgba(209, 230, 239, 0.5);

$iconColor: $buttonSpinnerColor;

$spinnerColor: white;
$spinnerBgColor: black;

$pulseBackground: #e7e7e7;
$pulseColor: #f3f3f3;

$statusErrorColor: #cc0000;