.textEntry {
  position: relative;
  height: 50vh;
  min-height: 40px;
  textarea {
    width: 100%;
    height: 100%;
    border-color: $fileSelectBorderColor;
  }
  &.over textarea,
  &.entry textarea,
  textarea:focus,
  textarea:hover {
    box-shadow: 0 0 0 0.2rem rgba(235,200,0,.25);
  }
}

.dropZone.drag {
  .textEntry.over textarea,
  textarea:hover {
    box-shadow: none;
  }
}

.dropZone.text {
  .textEntry {
    box-shadow: 0 0 0 0.2rem rgba(235,200,0,0.5);
  }
  textarea {
    box-shadow: 0 0 0 0.2rem rgba(235,200,0,.25);
  }
}