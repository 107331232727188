@import "variables";

* {
  box-sizing: border-box;
}

body {
  font-family: $defaultFont;
  font-weight: 400;
  color: $fontColor;
  font-size: $defaultFontSize;
  background-color: $bgBody;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  transition: box-shadow 200ms;
}

a, 
span.link {
  text-decoration: none;
  color: $linkColor;
}

a:hover,
span.link:hover {
  color: $linkColorHover;
  text-decoration: underline;
  transition: color 200ms;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $headingFont;
  font-weight: 700;
  padding: 0;
  margin: 0;
  line-height: 1.2em;
  color: $headingColor;
}

h2 {
//  font-size: 27px;
  font-size: 21px;
}

h3 {
//  font-size: 21px;
  font-size: 18px;
}

h4 {
  font-size: 18px;
}

p {
  margin: 0;
  padding: 0;
  font-size: $defaultFontSize;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1.5em;
  padding: 0;
  font-size: $defaultFontSize;
}

ol {
  li {
    padding: 0 0 10px 0;
  }
  ul {
    li {
      padding: 0;
    }
  }
}

li.noble {
  list-style: none;
}

select {
  font-family: $defaultFont;
  font-weight: 400;
  font-size: 14px;
  padding: 6px;
  line-height: 1.33;
  border: 1px solid $formBorderColor;
  border-radius: 2px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 100 80' width='100' height='80'%3E%3Cdefs%3E%3Cpath d='M 0 0 L 50 80 L 100 0 Z' id='a7ecCj0rP'%3E%3C/path%3E%3C/defs%3E%3Cg%3E%3Cuse xlink:href='%23a7ecCj0rP' opacity='1' fill='%23000000' fill-opacity='1'%3E%3C/use%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .5em auto, 100%;
}

// https://yoksel.github.io/url-encoder/
// <svg version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 80" width="100" height="80"><defs><path d="M 0 0 L 50 80 L 100 0 Z" id="a7ecCj0rP"></path></defs><g><use xlink:href="#a7ecCj0rP" opacity="1" fill="#000000" fill-opacity="1"></use></g></svg>

select::-ms-expand {
  display: none;
}

input[type="text"],
textarea {
  border: 1px solid $formBorderColor;
  border-radius: 2px;
  font-family: $defaultFont;
  font-weight: 400;
  font-size: 14px;
  padding: 6px;
  line-height: 1.33;
}

input[type="text"].readonly,
textarea.readonly {
  border-color: $buttonBgDisabledColor;
  background-color: $buttonBgDisabledColor;
}

input[type="text"].readonly:focus,
textarea.readonly:focus {
  box-shadow: none;
}

// .skipToMain is outside of the App so it is defined in index.html

.skipToMain:active,
.skipToMain:focus {
  position: static;
  clip: auto;
  width: auto;
  height: auto;
  overflow: visible;
  text-decoration: underline;  
}

.nav {
  background-color: $bgNavBar;
  list-style-type: none;
  font-size: 12px;
  color: $navColor;
  ul {
    padding: 0.5em 0 0.5em 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
  }
  li {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border-left: 1px solid $navPipeColor;
    a,
    a:active,
    a:visited {
      display: inline-block;
      padding: .2rem 1rem;
      color: $navColor;
    }
  }
  li.join {
    flex: 1;
    text-align: right;
    border-left: none;
  }
  li:first-child {
    border-left: none;
  }
  .logo {
    width: 103px;
    height: 30px;
    max-width: 103px;
    a {
      padding: 7px 20px 0 0;
      display: block;
      float: right;
    }
    img {
      width: 103px;
      height: 30px;
    }
  }
}
.banner {
  position: relative;
  padding-bottom: 1rem;
  background-color: $bgDefault;
  height: 90px;
  h1 {
    line-height: 72px;
    font-size: 36px;
    font-weight: 600;
    height: 72px;
    .title {
      white-space: nowrap;
      position: relative;
      display: block;
    }
    .productLogo {
      display: block;
      position: absolute;
      width: 36px;
      height: 36px;
      background-image: url(/images/refprep.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 26px 26px;
      top: 5px;
      left: 0;
    }
    a {
      color: $fontColor;
    } 
    a:hover {
      color: $linkColor;
      text-decoration: none;
    }
  }
  .edifix {
    flex: 1 1 100%;
    padding-left: 20px;
    transform: translate(0, 2px);
    a {
      display: block;
      float: right;
    }
    img {
      width: 125px;
    }
  }
  .mw240 {
    width: 260px;
    height: 70px;
    max-width: 260px;
    padding-left: 20px;
    a {
      display: block;
      float: right;
    }
    img {
      width: 240px;
      height: 70px;
    }
  }
  .mw120 {
    width: 150px;
    display: block;
    img {
      width: 120px;
    }
  }
}
.top-nav:after {
  position: relative;
  display: block;
  background-color: $bgDefault;
  content: "";
  width: 100%;
  height: 12px;
  font-size: 0;
  line-height: 0;
  box-sizing: border-box;
  border: solid;
  border-width: 0 12px 12px 0;
  border-color: #000 $bgNavBar transparent #000;
}  

.subNavBar {
  background-color: $subNavBgColor;
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: right;
  font-size: 11px;
  li {
    display: inline-block;
    list-style-type: none;
    a {
      display: inline-block;
      padding: 11px;
      color: $subNavLink;
    }
    a:hover,
    a:active {
      color: $subNavHoverLink
    }
  }
  li:last-child {
    background-color: $subNavLastLinkBgColor;
    a {
      color: white;
      padding-right: 22px;
    }
  }
}

.subNavBar:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 7.5px solid #fff;
  border-bottom: 7.5px solid #fff;
  border-color: #fff #fff transparent transparent;
  border-style: solid;
  border-width: 7.5px;
}

#main {
  position: relative;
  display: block;
  padding-bottom: 1.5rem;
  background-color: $bgDefault;
}

a.icon,
button.icon,
button.icon:hover {
  padding: 0;
  color: $buttonIconColor;
  background-color: transparent;
  transition: color 200ms;
  margin: 0;
  border: none;
  vertical-align: inherit;
}

a.icon.disabled,
a.icon.disabled:hover,
button.icon.disabled,
button.icon.disabled:hover {
  color: $buttonDisabledColor;
}

.error button.icon:not(.disabled) {
  color: $statusErrorColor;
}

a.icon:hover,
button.icon:hover {
  color: $buttonIconColorHover;
}

button.link {
  background-color: transparent;
  display: inline;
  padding: 0;
  margin: 0;
  border: none;
  color: $linkColor;
}

button.link:hover,
button.link:active {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: $linkColorHover;
}

.btn,
button {
  display: inline-block;
  padding: 6px 12px;
  border: 1px solid $buttonBgColor;
  border-radius: 2px;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
  color: $buttonColor;
  background-color: $buttonBgColor;
  vertical-align: middle;
  transition: background-color 200ms, color 200ms;
  line-height: 1.25;
}

.btn.lg,
button.lg {
  font-size: 22px;
  padding: 6px 36px;
  text-transform: uppercase;
}

.btn.secondary,
button.secondary {
  color: $buttonBgColor;
  background-color: $buttonColor;
}

.btn.secondary:hover,
button.secondary:hover,
.btn.secondary:active,
button.secondary:active {
  color: $buttonBgHoverColor;
  background-color: $buttonColor;
  border-color: $buttonBgHoverColor;
}

.btn.loading,
button.loading,
.btn.loading:hover,
button.loading:hover,
.btn.loading:active,
button.loading:active {
  background-color: $buttonBgSpinnerColor;
  border-color: $buttonBgSpinnerColor;
  color: $buttonSpinnerColor;
}

.btn.disabled,
button.disabled,
.btn.disabled:hover,
button.disabled:hover,
.btn.disabled:focus,
button.disabled:focus,
.btn.disabled:active,
button.disabled:active {
  background-color: $buttonBgDisabledColor;
  color: $buttonDisabledColor;
  border-color: $buttonBgDisabledColor;
}

.btn.error,
button.error,
.btn.error:hover,
button.error:hover,
.btn.error:focus,
button.error:focus,
.btn.error:active,
button.error:active {
  background-color: $buttonBgDisabledColor;
  border-color: $buttonBgDisabledColor;
  color: $buttonDisabledColor;
}

.btn:hover,
button:hover,
.btn:active,
button:active {
  background-color: $buttonBgHoverColor;
  border-color: $buttonBgHoverColor;
  transition: background-color 200ms;
  color: $buttonColor;
  text-decoration: none;
}
.btn:focus,
button:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(235,200,0,.25);
}

.btn + button,
button + .btn,
.btn + .btn,
button + button {
  margin-left: 1em;
}

.flexColumns {
  display: flex;
  .flexColumn50 {
    width: 50%;
  }
}

.fright,
button.fright.link,
button.fright.link:hover,
button.fright.link:active {
  float: right;
  display: block;
}

h2 {
  button.fright.link,
  button.fright.link:hover,
  button.fright.link:active {
    margin-top: 10px;
  }
}

.center {
  text-align: center;
}

@media (max-width: 799px) {
  .flexColumns {
    display: block;
    .flexColumn50 {
      width: auto;
    }
  }
}

/* 
 * For example
 * 600px wide screen = 
 * 0.05 * (.75 * 600px - 30px) + 15px
 * 0.05 * (370px) + 15px
 * 18.5px + 15px = 33.5px
 * 1388px = 65.55 
 * 1200px = 58.5px
 * 300px = 24.75px
 */

/* 3.75vw + 13.5 */
@media screen and (max-width: 1388px) {
  .banner,
  #main {
    padding-right: calc( ( 0.05 * ( 75vw - 30px ) ) + 15px );
    padding-left: calc( ( 0.05 * ( 75vw - 30px ) ) + 15px );
  }
}
/*
 * (1389 * .75 - 900) / 2 = 70.875
 * 37.5vw - 450px where 37.5vw is minimum of 520.875
 */
@media screen and (min-width: 1389px) {
  .banner,
  #main { 
    padding-left: calc( ( 75vw - 900px ) / 2 );
	  padding-right: calc( ( 75vw - 900px ) / 2 );
  }
}

.section {
  .bounds {
    padding: 20px 20px 10px 20px;
  }
  h2 {
//    margin-bottom: 24px;
    margin-bottom: 21px;
  }
  h3 {
//    margin-top: 24px;
//    margin-bottom: 21px;
    margin-top: 18px;
    margin-bottom: 15px;
  } 
  h4 {
    margin-top: 18px;
    margin-bottom: 15px;
  }
  p, 
  ul {
    margin-bottom: 16px;
  }
  ol ul,
  ul ul {
    margin-bottom: 0
  }
  h2.push, h3.push, h4.push, p.push, .lip.push {
    padding-top: 8px;
  }
  .lip.half {
    padding-top: 8px;
  }
  .lip {
    display: block;
    padding-bottom: 16px;    
  }
}

.section.bordered {
  border: 1px solid $borderColorLight;
}

.disabled {
  h3,
  p {
    color: $textDisabledColor;
  }
}

#footer {
  background-color: $bgFooter;
  padding: 0 30px 1rem;
  .nav {
    color: $footerNavColor;
    ul {
      align-items: center;
      justify-content: center;
      li {
        border-width: 0;
        a,
        a:active,
        a:visited {
          color: $footerNavColor;
        }
      }
    }
  }
  p {
    font-size: 13px;
    text-align: center;
    color: $footerFontColor;
    a,
    a:visited {
      color: $footerLinkColor;
      text-decoration: underline;
    }
    a:active {
      color: $footerNavColor;
    }
  }
}

@media (max-width: 599px) {
  .ieee-meta {
    display: none;
  }
}

// Accessibility Text
// https://zellwk.com/blog/hide-content-accessibly/
.j {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

