@import "variables";

label {
  padding: 0 3px;
  display: inline-block;
}

.stacked {
  label {
    padding: 0 0 5px 0;
    display: block;
  }
}

.i400 {
  width: 400px;
  max-width: 100%;
}

.t640,
.i640 {
  width: 640px;
  max-width: 100%;
}

.i200 {
  width: 200px;
}

.im120 {
  min-width: 120px;
}

.t80 {
  height: 80px;
}

.t120 {
  height: 120px;
}

.section p.radio,
p.radio {
  line-height: 20px;
}

.radioText {
  display: inline-block;
  padding: 0 3px;
}

.checked {
  font-weight: 700;
}

.recaptcha {
  margin: 6px 0;
  min-height: 78px;
}

.error {
  input[type="text"],
  textarea,
  input[type="password"] {
    border-color: $statusErrorColor;
    border-width: 2px;
    margin: -1px;
  }
}

.errormsg {
  display: inline-block;
  font-weight: bold;
  color: $statusErrorColor;
  padding-left: 1rem;
}