.icon-status-alert:before,
.icon-status-error,
.icon-status-ok:before {
  background-color: $bgDefault;
}

.processingPanel {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1rem;
  position: relative;
  overflow: auto;
  ul.processingStatus {
    padding: 0.5rem 24px;
    list-style: none;
    margin: 0;
    font-size: 16px;
    font-weight: 300;
    li {
      position: relative;
      font-size: 16px;
      .spinnerSpot {
        background-color: white;
        position: absolute;
        left: -22px;
        top: 0;
        z-index: 1;
      }
      .icon-small.icon-status-ok {
        position: absolute;
        font-size: 16px;
        color: $iconColor;
        top: 2px;
        left: -22px;
      }
      .icon-small.icon-status-ok:before {
        color: $iconColor;
      }
      &.status-error,
      &.status-alert,
      &.status-unknown,
      &.status-complete {
        position: relative;
        font-weight: bold;
        .icon-status-alert,
        .icon-status-error,
        .icon-status-unknown,
        .icon-status-ok {
          position: absolute;
          left: -24px;
          top: 0;
          font-size: 20px;
          z-index: 1;
        }
      }
    }
  }
}

@keyframes flow {
  0% {
    background-position: 100%, 0;
  }
  80% {
    background-position: 0%, 0;
  }
}
