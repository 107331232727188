.spinnerPage {
  display: block;
  position: absolute;
  top: calc(50vh - 50px);
  left: calc(50vw - 50px);
  width: 100px;
  height: 100px;
  animation: spin 1.5s infinite linear;
  z-index: 200;
}

/* h/t https://tobiasahlin.com/spinkit/ */
.spinnerSpot {
  display: inline-block;
  .spinnerRect {
    margin: 0 1px;
    display: inline-block;
    width: 3px;
    height: 12px;
    background-color: $iconColor;
    -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out;
    animation: sk-bouncedelay 1.2s infinite ease-in-out;
  }
  .spinnerRect1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .spinnerRect2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .spinnerRect3 {
    -webkit-animation-delay: 0;
    animation-delay: 0;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scaleY(0) }
  40% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    transform-origin: bottom;
  } 40% { 
    -webkit-transform: scaleY(1.0);
    transform: scaleY(1.0);
    transform-origin: bottom;
  }
}

.skeletonRow {
  background-color: $pulseColor;
  height: 1.5rem;
  width: 70%;
  margin: 1rem 0;
}

.skeletonPulse {
  background: linear-gradient(-90deg, $pulseBackground 0%, $pulseColor 50%, $pulseBackground 100%);
  background-size: 400% 400%;
  animation: pulse 2s ease-in-out infinite;
  transition: width 2s;
}

@keyframes pulse {
  0% {
    background-position: 0% 0%
  }
  100% {
    background-position: -135% 0%
  }
}

.btn.loading,
button.loading {
  .spinner {
    background-color: $buttonSpinnerColor;
    background: linear-gradient(to top right, $buttonSpinnerColor 35%, rgba($buttonSpinnerColor, 0) 70%);
  }
  .spinner:before {
    background-color: $buttonBgSpinnerColor;
  }
  .spinner:after {
    background-color: $buttonBgSpinnerColor;
  }
}

/* h/t https://projects.lukehaas.me/css-loaders/ */
.spinner {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  animation: spin 1.5s infinite linear;
  border-radius: 50%;
  background-color: $spinnerColor;
  background: linear-gradient(to right, $spinnerColor 10%, rgba($spinnerColor, 0) 42%);
  vertical-align: -2px;
}

.spinner:before {
  display: block;
  width: 50%;
  height: 50%;
  background-color: $spinnerColor;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.spinner:after {
  display: block;
  background-color: $spinnerBgColor;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;  
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
