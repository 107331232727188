.dropZone {
  position: relative;
  width: 100%;
  padding: 10px 0 20px;
  .fileUpload {
    position: relative;
    display: inline-block;
    .modern {
      display: none;
    }
    .legacy {
      position: absolute;
      z-index: 100;
      top: 0px;
      left: 0px;
    }
    .pct {
      display: inline-block;
    }
    button.icon.canceller {
      display: inline-block;
      font-size: 16px;
      transform: translate(2px, 2px);
      padding: 2px 0 0;
    }
    button.icon.canceller:focus {
      box-shadow: none;
    }
    &.isIE {
      .fileHandler {
        cursor: default;
      }
    }
  }
  .fileHandler {
    padding: 0 3px;
    cursor: pointer;
    position: relative;
    display: inline-block;
  }
  .checked {
    .fileHandler.hasFile {
      font-weight: bold;
    }
  }
}

.dropZone.drag {
  box-shadow: 0 0 0 0.2rem rgba(235,200,0,0.5);
}

.dropZone.dropped .fileHandler {
  box-shadow: 0 0 0 0.2rem rgba(235,200,0,0.5);
}

.fileHandler.error {
  color: $statusErrorColor;
}
